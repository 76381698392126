@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.team-card {
    width: 100%;
    height: 560px;
    max-width: 560px;
    color: $color-white;
    position: relative;
    &-heading {
        width: 80%;
        padding: $default-spacing;
        word-wrap: break-word;
        font-weight: 700 !important;
        margin-top: auto !important;
        @include dynamic-fonts(48px);
        @include mobile {
            width: 75%;
        }
        &--no-margin {
            margin-top: 0px !important;
            padding: $default-spacing $default-spacing 0 $default-spacing;
        }
        &--no-subtitle {
            padding: $default-spacing $default-spacing $default-spacing / 2 $default-spacing;
        }
    }
    &-subheading {
        padding: 0 $default-spacing $default-spacing / 2 $default-spacing;
        @include dynamic-fonts(26px);
        &--hidden {
            display: none;
        }
    }
    &-button {
        height: 64px;
        width: 64px;
        position: absolute !important;
        bottom: 0;
        right: 0;

    }
    &--show-body {
        flex-direction: column !important;
    }
    &-body {
        @include dynamic-fonts(20px);
        padding: 0 $default-spacing;
    }
    @include tablet {
        width: 560px;
    }
    @include desktop {
        width: 560px;
        max-width: unset;
    }
}
