@import "Styles/Colours.scss";
@import "Styles/Variables.scss";

.contact-form,
.contact-info {
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.contact-form {
  .contact-form-title {
    font-size: $font-size-subtitle * 1.2;
    font-family: $font-family-condensed;
    color: $color-text-gray;
  }

  button {
    background: $color-secondary !important;
    margin: auto;
    padding: $default-spacing/2 100px $default-spacing/2 100px;
    @include dynamic-fonts($font-size-links)
    .MuiCircularProgress-colorPrimary {
      color: #f0f0f0 !important;
    }
  }

  button:disabled {
    background-color: $color-gray !important;
  }

  .MuiTextField-root {
    padding-bottom: $default-spacing;
    width: 80%;
    @include mobile {
      width: 90%;
    }
  }
}
