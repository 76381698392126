@import "Styles/Colours.scss";
@import "Styles/Variables.scss";

.footerlist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .footerlist-item {
    padding: 0;
    align-items: flex-start;

    .icon {
      color: $color-gray;
      min-width: 20px;
      padding-right: $default-spacing/2;
    }

    .secondary-text {
      flex: initial;
      color: $color-gray;
      font-size: $font-size-links;
      padding-right: $default-spacing/2;
    }

    .primary-text-link {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      text-decoration-color: $color-dm-link;
      &:visited {
        text-decoration-color: $color-dm-link-visited;
      }
      &:active {
        text-decoration-color: $color-secondary;
      }
      &:hover {
        opacity: 0.7;
      }
    }

    .primary-text {
      flex: initial;
      color: $color-white;
      font-size: $font-size-links;
    }
  }
}

.hour-items {
  flex-direction: column;
  align-items: flex-start;
  @include tablet {
    flex-direction: row;
  }
  @include desktop {
    flex-direction: row;
  }
}
