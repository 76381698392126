@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.about-page {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  .about-section {
    margin-top: $default-spacing * 2;
    padding: 0 24px 0 24px;
    display:flex;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
      margin-top: $default-spacing * 5;
    }
    &--last {
      margin-bottom: $default-spacing * 8;
    }
    .about-section-text {
      text-align: center;
      font-weight: 300;
      @include dynamic-fonts($font-size-content);
      @include desktop {
        width: 50%;
      }
    }
    .about-section-image {
      text-align: center;
      max-height: 400px;
      margin-top: $default-spacing * 2;
      margin-right: unset;
      @include mobile {
        margin-right: 0px;
        margin-left: 0px;
      }
      @include desktop {
        width: 50%;
        margin-top: 0;
        margin-right: 0;
      }
      img {
        width: 100%;
        height: auto;
        max-height: 400px;
        object-fit: cover;
        @include desktop {
          margin-top: 0;
          width: 100%;
        }
      }
      figcaption {
        margin: 12px 0 12px 0;
      }
    }
  }
}

.about-team {
  align-items: center;
  padding: 0 $default-spacing 0 $default-spacing;
  @include mobile {
    padding: 0 $default-spacing / 2 0 $default-spacing / 2;
  }
  h2 {
    text-align: center;
    margin-bottom: $default-spacing * 2;
  }
  &-cards {
    justify-content: center;
    align-items: center;
    margin-bottom: $default-spacing * 2;
    gap: 2em;
    flex-wrap: wrap;
    width: 100%;
    @include mobile {
      align-items: center;
      flex-direction: column;
    }
  }
}
