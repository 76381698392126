@import "Styles/Colours.scss";
@import "Styles/Variables.scss";

.language-button {
  font-weight: 300 !important;
}

.flag-icon {
  margin-right: 2px;
  height: 12px;
}
