@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.services-page {
  padding-bottom: $default-spacing;
  margin-top: 0 !important;
  @include desktop {
    margin-top: $default-spacing * 2 !important;
    display: flex !important;
  }

  .sticky-nav {
    position: -webkit-sticky;
    position: sticky;
    margin-left: calc(50% - 50vw) !important;
    margin-right: calc(50% - 50vw) !important;
    background-color: $color-background-white;
    border-bottom: 1px solid #b5b5b5;
    top: 90px;
    overflow-x: auto;

    @include mobile {
      top: 70px;
    }
    @include desktop {
      border-bottom: 0;
      overflow-x: unset;
      margin: 0 !important;
    }
  }

  .linebreak {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
  }

  .flavor {
    margin: $default-spacing 0 $default-spacing * 2 0;
    @include desktop {
      margin-top: $default-spacing * 4;
    }
    .flavor-text {
      font-weight: 300;
    }
  }

  margin: $default-spacing * 2 0 $default-spacing * 3 0;
  h2 {
    font-size: $font-size-subtitle;
    font-weight: 400;
  }
}
