@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

form {
  flex: 1;
  display: flex;
  flex-direction: column;
  .submit {
    margin: $default-spacing auto;
    @include dynamic-fonts($font-size-links);
    @include tablet {
      margin: $default-spacing 25%;
      padding: $default-spacing $default-spacing * 4;
    }
    @include desktop {
      margin: $default-spacing 25%;
    }
    background-color: $color-secondary !important;
    padding: $default-spacing/2 100px $default-spacing/2 100px;

    .MuiCircularProgress-colorPrimary {
      color: #f0f0f0 !important;
    }
  }
  .submit:disabled {
    background-color: $color-gray !important;
  }
  .careers-form {
    .careers-form-radios {
      margin-bottom: $default-spacing;
    }
    .MuiRadio-colorSecondary.Mui-checked {
      color: $color-primary;
    }
    .MuiTextField-root {
      padding-bottom: $default-spacing;
      width: 100%;
      .MuiFilledInput-underline {
        border-radius: 0;
      }
    }
    .info-text {
      font-weight: 300;
      font-size: $font-size-subtitle * 0.7;
      @include desktop {
        font-size: $font-size-subtitle * 0.8;
      }
    }
    .resume-upload {
      margin-bottom: $default-spacing;
      display: flex;
      justify-content: center;

      div {
        align-self: center;
      }
      p {
        padding: $default-spacing;
        @include dynamic-fonts($font-size-content);
      }
    }
    .careers-form-resume {
      #file-attachment {
        margin: $default-spacing 0 $default-spacing 0;
      }
      .MuiGrid-root.MuiGrid-container {
        margin-bottom: $default-spacing / 2;
      }
    }
  }
}

// conditional styled underlining
.blackUnderline {
  label.Mui-focused {
    color: black;
  }
  .MuiFilledInput-underline:after {
    border-bottom-color: black;
  }
}
