@import "Styles/MediaQueries.scss";

$font-size-header: 90px;
$font-size-landing-header: 76px;
$font-size-header-mobile: 65px;
$font-size-title: 48px;
$font-size-subtitle: 28px;
$font-size-links: 20px;
$font-size-content: 26px;

$navbar-height: 90px;
$default-spacing: 16px;

$font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
$font-family-condensed: "Roboto condensed", "Helvetica", "Arial", "sans-serif";

@mixin dynamic-fonts($baseSize) {
  font-size: $baseSize * 0.75 !important;
  @include tablet {
    font-size: $baseSize * 0.8 !important;
  }
  @include desktop {
    font-size: $baseSize !important;
  }
}
