@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.project-card {
  margin: $default-spacing;
  margin-bottom: $default-spacing * 2;
  margin-top: 0;
  width: 100%;
  &__images {
    display: flex;
    gap: 1em;
    overflow: scroll;
    width: 100%;
    /* width */
    &::-webkit-scrollbar {
      width: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .MuiCardMedia-root {
      min-width: 325px;
    }
    &--single {
      width: 100%;
    }
  }
  @include tablet {
    width: 40%;
    min-width: 250px;
  }
  @include desktop {
    width: 30%;
    min-width: 250px;
  }

  .MuiCardMedia-root {
    height: 200px;
  }
}
