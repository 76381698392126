@import "Styles/Colours.scss";

html, body, #root {
  height: 100%;
}

.App {
  min-width: 320px;
  height: 100%;
  display: flex !important;
  flex-direction: column;
}

.MuiButton-label {
  color: $color-white;
}
