@import "Styles/Variables.scss";
@import "Styles/Colours.scss";
@import "Styles/MediaQueries.scss";

.careers-page {
  padding-bottom: $default-spacing * 3;
  .flavor {
    margin: $default-spacing * 2 0 $default-spacing * 2 0;

    .flavor-text {
      font-weight: 300;
      font-size: $font-size-subtitle * 0.8;
      @include desktop {
        font-size: $font-size-subtitle;
      }
    }
  }
}
