@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.contact-page {
  display: inline !important;
  padding-bottom: $default-spacing * 2;

  @include desktop {
    display: flex !important;
    flex-wrap: wrap;
  }
  .contact-info {
    display: flex;
    align-items: center;
    margin-left: 0;
    @include desktop {
      align-items: flex-start;
    }

    iframe {
      margin: $font-size-subtitle * 1.3 0 $default-spacing * 1.3 0;
      width: 80%;
      @include mobile {
        width: 90%;
      }
      @include desktop {
        width: 100%;
      }
    }
    .skeleton {
      width: 100%;
      height: 50%;
      margin: $font-size-subtitle * 1.2 0 $default-spacing 0;
    }
    .hidden {
      display: none;
    }

    .contact-info-div {
      width: 90%;
      padding: 0 $default-spacing 0 $default-spacing;
      text-align: center;
      @include desktop {
        text-align: left;
        padding: 0;
      }

      .contact-info-title {
        font: bold $font-size-subtitle Roboto;
        margin-top: 0px;
      }

      .contact-info-text {
        font: $font-size-content Roboto;
        margin-top: 0;
        white-space: pre-line;
      }
    }
  }
}

// conditional styled underlining
.blackUnderline {
  label.Mui-focused {
    color: black;
  }
  .MuiFilledInput-underline:after {
    border-bottom-color: black;
  }
}
