@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.landing {
  margin-top: 90px;
  @include mobile {
    margin-top: 70px;
  }
  .landing-section:nth-of-type(2n + 1) {
    color: $color-text-gray;
  }
  .landing-section:nth-of-type(2n) {
    color: $color-light-gray;
    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
      url("../../Assets/images/landing/divider.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-title {
    display: flex;
    background-image: url("../../Assets/images/landing/landing-header.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: relative;
    height: 90vh;
    @include desktop {
      height: 720px;
      max-width: 1800px;
      margin-left: auto;
      margin-right: auto;
    }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(transparent 75%, black 100%);
      opacity: 0.9;
      height: 90vh;
      @include desktop {
        height: 720px;
      }
    }
    &-shadow {
      display: none;
      @include desktop {
        display: unset;
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 16px 0 16px -4px black inset, -16px 0 16px -4px black inset; 
      }
    }

    .landing-title-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-content: center;
      text-align: center;
      color: $color-white;
      width: 100%;
      height: 100%;
      z-index: 4;
      @include tablet {
        text-align: left;
        padding-bottom: $default-spacing * 2;
        margin-left: $default-spacing * 2 !important;
      }
      @include desktop {
        text-align: left;
        margin-left: $default-spacing * 2 !important;
      }

      h1 {
        @include dynamic-fonts($font-size-landing-header);
        font-weight: bold;
      }
      p {
        @include dynamic-fonts($font-size-subtitle);
        padding-left: 4px;
        margin-bottom: $default-spacing;
      }
    }
  }
  #services-section {
    display: flex;
    justify-content: center;
  }
  .landing-section {
    width: 100%;
    display: flex;
    text-align: center;
    padding: $default-spacing * 3 0 $default-spacing * 3 0;
    @include desktop {
      padding: 10vh 0 10vh 0;
    }
    .landing-section-flex {
      max-width: 1600px;
    }
    h2 {
      @include dynamic-fonts(60px);
      font-weight: 500;
    }
    p {
      @include dynamic-fonts($font-size-content);
    }
    .services-button {
      width: 100%;
      display: inline-block;
    }
    button {
      padding: $default-spacing/2 $default-spacing;
      margin: auto;
      background: $color-secondary !important;
      @include dynamic-fonts($font-size-links);
    }
    .landing-section-body {
      margin: 0 $default-spacing * 4 $default-spacing * 2 $default-spacing * 4;
      padding-top: $default-spacing * 2;
      font-weight: 400;
      &--projects {
        margin: 0 $default-spacing * 4 $default-spacing * 2 $default-spacing * 4;
      }
    }
    .landing-services-body {
      display: flex;
      flex-direction: column;
      @include desktop {
        flex-direction: row;
        margin-bottom: $default-spacing * 2;
        padding: $default-spacing * 2 $default-spacing * 4;
        align-items: center;
      }
      .landing-section-body {
        @extend .landing-section-body;
        @include desktop {
          float: left;
          width: 45%;
          margin-bottom: 0px;
        }
      }
      .landing-services-image {
        text-align: center;
        order: -1;
        margin-top: $default-spacing;
        @include desktop {
          order: 1;
          float: right;
          width: 40%;
        }
        img {
          width: 75%;
          height: 350px;
          object-fit: cover;
          @include desktop {
            width: 100%;
          }
        }
        h6 {
          margin: $default-spacing 0 0 0;
          font-weight: 300;
          @include dynamic-fonts($font-size-subtitle);
        }
      }
    }
    .landing-projects-image {
      margin-bottom: $default-spacing * 2;
      padding-bottom: $default-spacing;
      white-space: nowrap;
      overflow-x: scroll;
      
      @include desktop {
        overflow-x: unset;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 0;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      img {
        margin-right: $default-spacing;
        &.no-margin {
          margin-right: 0;
        }
        width: 200px;
        height: 200px;
        object-fit: cover;
      }
    }
  }
}
