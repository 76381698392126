@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.divider {
  background-image: url("../../Assets/images/landing/divider.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 90px !important;
}

.filter-gradient {
  .divider-text {
    font-family: $font-family-condensed;
    padding-top: $default-spacing;
    @include dynamic-fonts($font-size-subtitle);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: linear-gradient(to top, transparent 0%, black 90%),
    linear-gradient(to bottom, transparent 0%, black 90%);
  height: 100% !important;
  width: 100% !important;
}
