@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.service {
  margin-top: $default-spacing;
  min-height: 400px;
  display: flex;
  .service-container {
    display: flex;
    min-height: 400px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: $default-spacing * 2;

    @include desktop {
      flex-wrap: nowrap;
    }

    .service-body,
    .service-image {
      min-width: 400px;

      @include mobile {
        min-width: 250px;
      }
    }

    .service-body {
      flex: 1;
      @include desktop {
        padding-right: $default-spacing * 2;
      }
      margin-bottom: $default-spacing * 2;
      h2 {
        margin-bottom: $default-spacing;
      }
      .service-body-text {
        font-weight: 300;
        @include dynamic-fonts($font-size-content);
      }
      .service-body {
        flex: 1;
        padding-right: $default-spacing * 2;
        margin-bottom: $default-spacing * 2;
        .service-body-text {
          font-weight: 300;
        }
        @include mobile {
          padding-right: 0px;
        }
      }
    }

    .service-image {
      margin-top: 0px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      max-height: 400px;
      figcaption {
        margin: 12px 0 12px 0;
        max-width: 400px;
        text-align: center;
      }
      @include tablet {
        padding-left: $default-spacing;
      }
      @include desktop {
        padding-left: $default-spacing;
      }

      img {
        object-fit: cover;
        max-height: 300px;
        max-width: 400px;
        @include mobile {
          min-width: 250px;
        }
      }
    }
  }
}
