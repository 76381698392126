@import "Styles/Colours.scss";
@import "Styles/MediaQueries.scss";

.toolbar {
  box-shadow: none !important;
  &-content {
    width: 100%;
    max-width: 1600px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: auto;
    margin-right: auto;
  }
  a:-webkit-any-link {
    text-decoration: none;
  }
  .MuiTab-root {
    padding-left: 0px;
    padding-right: 0px;
    min-width: 100px;
  }
  .MuiButtonBase-root:hover,
  .MuiButtonBase-root:focus {
    opacity: 1;
  }
  .toolbar-logo {
    margin-right: auto;
    margin: 5px auto 5px 0;
    img {
      width: 200px;
      height: 100%;
      @include mobile {
        width: 150px;
      }
    }
  }

  .toolbar-logo-image {
    display: flex;
  }

  .MuiTab-wrapper,
  .MuiButton-label {
    color: $color-white;
  }
}
