@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.page-header {
  display: block;
  position: relative;
  background-size: cover;
  min-height: 560px;
  margin-top: $navbar-height;
  h1 {
    z-index: 10;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(transparent 0, white 100%);
  }
  @include mobile {
    margin-top: $navbar-height - 50;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .page-header-text {
    font-family: "Roboto";
    color: $color-secondary;
    @include dynamic-fonts($font-size-header);
  }
}
