@import "Styles/Colours.scss";
@import "Styles/Variables.scss";

.footer {
  background-color: $color-primary;
  padding: $default-spacing $default-spacing * 2;
  margin-top: auto;

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .footer-column {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      padding: $default-spacing $default-spacing * 2;

      .footer-logo {
        display: flex;
        margin: 0 auto;
        width: 200px;
        height: 80px;
      }

      .footer-subtitle {
        color: $color-footer-subtitle;
        font-family: "Roboto Condensed";
        font-weight: bold;
        font-size: $font-size-subtitle;
        margin: 0;
      }
      
      .footer-allHourService {
        color: $color-white;
        margin-top: 0px;
      }
    }
  }
}
