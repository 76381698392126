@import "Styles/Colours.scss";
@import "Styles/Variables.scss";
@import "Styles/MediaQueries.scss";

.services-nav {
  min-width: 100px;
  text-align: center;
  position: sticky;
  padding: $default-spacing;
  white-space: nowrap;
  overflow-x: scroll;

  @include tablet {
    overflow-x: hidden;
  }

  @include desktop {
    overflow-x: hidden;
    max-width: 250px;
  }
  
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    height: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @include desktop {
    width: 15vw;
    border-bottom: 0;
    padding: 0;
    text-align: left;
    position: sticky;
    margin: $default-spacing $default-spacing 0 0;
    top: 125px;
    float: left;
  }
  display: flex;
  flex-direction: column;
  color: $color-gray;
  h2 {
    font-size: $font-size-subtitle;
    @include desktop {
      margin-bottom: $default-spacing / 2;
    }
    color: $color-primary;
  }
  .MuiLink-root {
    color: $color-gray;
    margin: $default-spacing / 2 $default-spacing / 2 $default-spacing / 2;

    @include desktop {
      margin: 0 0 $default-spacing / 2 0;
      padding: 0 $default-spacing / 2 0 $default-spacing / 2;
      display: block;
      padding: 0;
    }
  }

  h3 {
    text-decoration: none;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: $font-size-links;
    text-align: left;
    @include desktop {
      width: 15vw;  
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  button:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $color-dark-gray;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  .active h3,
  button:hover {
    color: $color-dark-gray !important;
  }

  .active:before {
    visibility: visible;
    text-decoration: none;
    width: 100%;
  }
}
