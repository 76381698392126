//Primary
$color-primary: #0b1210;
$color-secondary: #a31621;
$color-gray: #a9a9a9;
$color-light-gray: #f4f4f4;
$color-dark-gray: #373737;
$color-white: #fffafa;
$color-background-white: white;

//dm = dark mode
$color-dm-link-visited: #7a2dd8;
$color-dm-link: #2734dd;

$color-text-gray: #222222;
$color-footer-subtitle: #c91331;

:export {
  color-primary: $color-primary;
  colorSecondary: $color-secondary;
  color-gray: $color-gray;
  color-dark-gray: $color-dark-gray;
  color-white: $color-white;
}
