/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("../Assets/fonts/roboto-v20-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../Assets/fonts/roboto-v20-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-regular - latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"),
    url("../Assets/fonts/roboto-condensed-v18-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../Assets/fonts/roboto-condensed-v18-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// /* fontawesome-brands */
// @font-face {
//   font-family: "FontAwesome Brands";
//   font-style: normal;
//   font-weight: 400;
//   src: local("FontAwesome Brands"), url("../Assets/fonts/fa-brands-400.woff2") format("woff2"),
//     /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../Assets/fonts/fa-brands-400.woff")
//       format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }

// /* fontawesome-regular */
// @font-face {
//   font-family: "FontAwesome Regular";
//   font-style: normal;
//   font-weight: 400;
//   src: local("FontAwesome Regular"), url("../Assets/fonts/fa-regular-400.woff2") format("woff2"),
//     /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../Assets/fonts/fa-regular-400.woff")
//       format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
